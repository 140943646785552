import React, {useState} from 'react';
import {ResponseNivs} from "./ImportNivsContainer";
import {LoadingView, UploadField, Button, ButtonClasses} from "@cuatroochenta/co-admin-react-library";

interface ImportNivsViewProps {
    importNivs: (file: File, stopLoading: () => void) => void
    responseNivs: ResponseNivs | null
}

interface state {
    file: File | null
    loading: boolean,
    helperText: string
}

export const ImportNivsView = (props: ImportNivsViewProps): React.ReactElement => {

    const [file, setFile] = useState<File | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [helperText, setHelperText] = useState<string>('');
    const requiredText = 'Campo obligatorio';

    const renderFileSelector = (): React.ReactNode => {
        return (
            <div className="form-group">
                <div className="component-show-field-form">
                    <UploadField
                        value={file ? file.name : ''}
                        field={'file'}
                        type={''}
                        disabled={false}
                        taskUpload={(e: any) => onUploadFile(e.target.files ? e.target.files[0] : null)}
                        removeFile={() => {
                            setFile(null);
                            setHelperText(requiredText);
                        }}
                        isEditScreen={true}
                        name={'file'}
                        label={"Selecciona un archivo *"}
                        helperText={helperText}
                    />
                </div>
            </div>
        );
    }

    const onUploadFile = (file: File | null): void => {
        setFile(file);
        setHelperText('');
    };

    const importNivs = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault()
        if (!file) {
            setHelperText(requiredText);
        }
        if (file !== null && !loading) {
            setLoading(true);
            props.importNivs(file, () => setLoading(false));
        }
    }

    const renderGenerateButton = () => {
        return (
            <Button
                type={'submit'}
                buttonClass={ButtonClasses.CUSTOM_BUTTON_FILLED}
                content={'Importar Nivs'}
            />
        )
    }

    const renderMessageCard = () => {
        if (props.responseNivs && props.responseNivs.response === null || loading) {
            return null;
        }

        if (props.responseNivs && props.responseNivs.response.success && props.responseNivs.response.message) {
            return renderMessage(props.responseNivs.response.message);
        }

        return renderMessage(props.responseNivs && props.responseNivs.response.error, {color: "red"});
    }

    const renderMessage = (message: string | null, style: {} = {}) => {
        return (
            <div className="col-md-12">
                <div className="card shadow p-3">
                    <div className="card-body">
                        <div className="message">
                            <label style={style}>{message}</label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    if (loading) {
        return <LoadingView loading={loading}/>;
    }

    return (
        <div>
            {renderMessageCard()}
            <div className="col-md-12">
                <div className="card shadow p-3">
                    <div className="card-body">
                        <form onSubmit={(e) => importNivs(e)}>
                            <div className={"row"}>
                                {renderFileSelector()}
                            </div>
                            {renderGenerateButton()}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}